<template>
  <div class="foundation_page">
    <div class="senate_top">
      <div class="my_banner">
        <img src="../../assets/pc_img/jci_banner.png" alt="">
      </div>
      <div class="banner_content">
        <h3>JCI FOUNDATION</h3>
        <p>More than 50 years ago, the JCI Senate was established to recognize the outstanding achievements and service
          of JCI members worldwide.</p>
      </div>
      <topNavBar></topNavBar>
    </div>
    <div class="jci_recognizes">
      <div class="back">
        <img src="../../assets/pc_img/story.png" alt="">
      </div>
      <div class="rec_content">
        <h3 class="title">
          Invest in JCI through the JCI Foundation.
        </h3>
        <div class="my_content">
          <p>More than 50 years ago, the JCI Foundation was established to empower JCI members and friends to
            financially invest in the future of JCI. Since it began, the JCI Foundation has raised more than US $8
            million to support specific efforts contributing to growth and development, leadership development, training
            and Local or National Organization extension.The JCI Foundation is a registered 501(c)3 charitable
            organization in the United States.</p>
          <div class="my_btn">
            <div class="btn_com" @click="toDetail(1)">VIEW THE JCI FOUNDATION STAKEHOLDERS</div>
            <div class="btn_com" @click="toDetail(2)">VIEW THE JCI FOUNDATION DONOR LEVELS</div>
          </div>
        </div>
      </div>
    </div>
    <div class="tomorrow">
      <div class="content">
        <h4>Invest in tomorrow</h4>
        <p>Donors who support JCI through monetary contributions to the JCI Foundation invest in the future of the
          organization.</p>
        <div class="tom_btn">
          <div class="btn_com" @click="toDetail">INVEST</div>
        </div>
      </div>
    </div>
    <div class="remembering">
      <div class="right">
        <h3 class="title">
          Why invest in the JCI Foundation?
        </h3>
        <p class="detail">Contributing to this fund gives you the opportunity to actively support JCI organizations and
          initiatives around the world. The JCI Foundation empowers JCI members to turn their goals into a reality while
          creating sustainable change.Your contributions will support initiatives such as leadership training,
          membership recruitment, infrastructure, local and national events and skills development.</p>
        <div class="content">
          <div class="item">
            <div class="img_box"><img src="../../assets/pc_img/impact.png" alt=""></div>
            <span>Magnify impact</span>
            <p>The JCI Foundation magnifies the impact of each donation by accruing interest that is then used to fund
              initiatives that build a better future.</p>
          </div>
          <div class="item">
            <div class="img_box"><img src="../../assets/pc_img/leaders.png" alt=""></div>
            <span>Build new skills & leaders</span>
            <p>JCI members never stop learning. The JCI Foundation financially supports programs that build capacity and
              create new skills for future leaders of JCI.</p>
          </div>
          <div class="item">
            <div class="img_box"><img src="../../assets/pc_img/future.png" alt=""></div>
            <span>Create a strong future</span>
            <p>The JCI Foundation offers development grants to JCI’s nearly 120 National Organizations to support and
              empower local members to increase their impact.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="change">
      <div class="content">
        <h4>Take action to create positive change.</h4>
        <div class="inform">
          <img src="../../assets/pc_img/contribution.png" alt="">
          <span>Make a contribution</span>
          <p>A contribution to the JCI Foundation is an investment in the future. JCI members who donate US $100 or more
            to the JCI Foundation are recognized with one of several levels. Your donations add up over time, so little
            by little, you can achieve the highest level. Help sustain JCI's legacy by contributing today.</p>
          <div class="cha_btn">
            <div class="btn_com" @click="toDetail">MAKE A CONTRIBUTION</div>
          </div>
        </div>
      </div>
    </div>
    <div class="donate">
      <img src="../../assets/pc_img/donate.png" alt="">
      <div class="content">
        <span>Donate</span>
        <p>Support our impact! Donations fund numerous national and international projects contributing to training,
          development and sustainable, positive change around the world.</p>
        <div class="don_btn">
          <div class="btn_com" @click="toDetail">DONATE</div>
        </div>
      </div>
    </div>
    <pageFooter />
  </div>
</template>

<script>
  import pageFooter from "./components/pageFooter";
  import topNavBar from "./components/topNavBar";

  export default {
    components: {
      topNavBar,
      pageFooter
    },
    methods: {
      toDetail(value) {
        if (value == 1) {
          window.open('https://jci.cc/web/#/foundation?type=1')
        } else if (value == 2) {
          window.open('https://jci.cc/web/#/foundation?type=2')
        } else {
          window.open('https://jci.cc/web/#/foundation-form')
        }
      },
    },
  };
</script>

<style scoped lang='less'>
  .foundation_page {
    .senate_top {
      position: relative;
    }

    .my_banner {
      width: 100%;

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .banner_content {
      position: absolute;
      top: 50%;
      left: 50%;
      color: #fff;
      text-align: center;
      width: 680px;
      transform: translateX(-50%) translateY(-50%);
      font-size: 20px;

      h3 {
        font-size: 60px;
        margin-bottom: 60px;
      }
    }

    .jci_recognizes {
      position: relative;
      background-color: #f4f4f4;
      width: 100%;

      img {
        width: 100%;
        height: 590px;
      }
    }

    .rec_content {
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      .my_content {
        width: 1000px;
        font-size: 20px;
        text-align: left;
        margin: 80px auto 0;
      }

      .my_btn {
        display: flex;
        font-size: 12px;

        div {
          margin-top: 45px;
        }
      }
    }

    .btn_com {
      height: 50px;
      line-height: 50px;
      background: #4266b0;
      border-radius: 25px;
      color: #fff;
      padding: 0 26px;
      margin-right: 20px;
      cursor: pointer;
    }

    .title {
      border-bottom: 4px solid #EDBE38;
      text-align: right;
      padding-bottom: 10px;
      width: 1000px;
      font-size: 32px;
      font-weight: 700;
    }

    .remembering {
      display: flex;
      align-items: center;
      height: 700px;

      .right {
        flex: 1;
        display: flex;
        flex-direction: column;

        .title {
          text-align: left;
          align-self: end;
        }

        .content {
          display: flex;
          margin-top: 80px;
          justify-content: space-around;
          padding: 0 30px;
        }

        .detail {
          font-size: 18px;
          align-self: end;
          width: 1000px;
          text-align: left;
          margin-top: 30px;
          padding-right: 70px;
        }

        .item {
          width: 28%;
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #0D0F30;

          p {
            font-size: 14px;
          }

          .img_box {
            height: 145px;
            width: 190px;
            position: relative;

            img {
              position: absolute;
              left: 0;
              bottom: 0;
            }
          }
        }

        span {
          font-size: 18px;
          margin: 38px 0 15px;
        }

      }
    }

    .tomorrow {
      width: 100%;
      height: 256px;
      background: #4266b0;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;

      .content {
        width: 1000px;
      }

      h4 {
        font-size: 18px;
      }

      p {
        font-size: 16px;
        margin: 32px 0 26px;
        width: 730px;
      }

      .tom_btn {
        display: flex;

        div {
          text-align: center;
          width: 170px;
          background-color: #fff;
          color: #4266B0;
          font-size: 16px;
        }
      }
    }

    .change {
      width: 100%;
      height: 610px;
      background: #f4f4f4;
      display: flex;
      justify-content: center;
      align-items: center;

      h4 {
        font-size: 24px;
      }

      .inform {
        width: 1200px;
        background-color: #fff;
        margin-top: 50px;
        height: 380px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 110px;
      }

      span {
        margin: 40px 0 30px;
        font-size: 18px;
      }

      p {
        font-size: 14px;
        margin-bottom: 55px;
      }
    }

    .donate {
      position: relative;
      width: 100%;
      height: 250px;
      object-fit: cover;
      color: #fff;

      .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }

      span {
        font-size: 18px;
      }

      p {
        font-size: 14px;
        width: 820px;
        margin-top: 35px;
      }

      .don_btn {
        display: flex;
        margin-top: 28px;
        justify-content: center;

        div {
          background-color: #fff;
          width: 170px;
          color: #4266B0;
          font-size: 16px;
        }
      }
    }
  }
</style>